import React from 'react'
import PropTypes from 'prop-types'

import { formatTitle } from '../../../helpers/stringHelpers'

import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'

export default function HomeQuote( props ) {

	const data = props.input

	return (
		<section className="home-quote">
			<div className="home-quote__wrapper wrapper">
				<div className="home-quote__quote-container">
					<h2 className="home-quote__quote" dangerouslySetInnerHTML={ formatTitle( data.quote ) } />
					<span className="home-quote__quote-author">{ data.author }</span>
				</div>
			</div>
			<AnimatedStroke pathType={ 'homepage-quote' } cssClass="home-quote__stroke" pixelDelay={ 225 } reverse={ false } />
		</section>
	)
}

HomeQuote.propTypes = {
	input: PropTypes.object.isRequired
};