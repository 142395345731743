import React from 'react'
import Img from 'gatsby-image'

export default function Image ( props ) {

	const { image, title, displayBorder } = props.input

	return (
		<section className={`image ${ !displayBorder ? 'image--no-border' : '' }`}>
			<div className="image__wrapper wrapper">
				<Img fluid={{ ...image.imageFile.childImageSharp.fluid, aspectRatio: 16/9 }} className="image__image" />
				{ title && <span className="image__title">{ title }</span> }
			</div>
		</section>
	)
}
