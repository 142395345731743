import React from 'react'

import BarChartIcon from '../../images/svgs/bar-chart-icon.svg'
import BulbIcon from '../../images/svgs/bulb-icon.svg'
import ClockIcon from '../../images/svgs/clock-icon.svg'
import GlobeIcon from '../../images/svgs/globe-icon.svg'
import GroupIconOne from '../../images/svgs/group-icon-1.svg'
import GroupIconTwo from '../../images/svgs/group-icon-2.svg'
import GroupIconThree from '../../images/svgs/group-icon-3.svg'
import GroupIconFour from '../../images/svgs/group-icon-4.svg'
import HandsIcon from '../../images/svgs/hands-icon.svg'
import HatIcon from '../../images/svgs/hat-icon.svg'
import LaptopIcon from '../../images/svgs/laptop-icon.svg'
import LineChartIcon from '../../images/svgs/line-chart-icon.svg'
import MailIcon from '../../images/svgs/mail-icon.svg'
import MoneyIcon from '../../images/svgs/money-icon.svg'
import NoteIconOne from '../../images/svgs/note-icon-1.svg'
import NoteIconTwo from '../../images/svgs/note-icon-2.svg'
import PersonIconOne from '../../images/svgs/person-icon-1.svg'
import PersonIconTwo from '../../images/svgs/person-icon-2.svg'
import PersonIconThree from '../../images/svgs/person-icon-3.svg'
import TreeIcon from '../../images/svgs/tree-icon.svg'

export default function Icon ( props ) {

	const { icon, cssClass } = props

	const getIconType = ( type, cssClass ) => {

		switch ( type ) {
			case 'barChart':
				return <BarChartIcon className={ cssClass } />
			case 'bulb':
				return <BulbIcon className={ cssClass } />
			case 'clock':
				return <ClockIcon className={ cssClass } />
			case 'globe':
				return <GlobeIcon className={ cssClass } />
			case 'group1':
				return <GroupIconOne className={ cssClass } />
			case 'group2':
				return <GroupIconTwo className={ cssClass } />
			case 'group3':
				return <GroupIconThree className={ cssClass } />
			case 'group4':
				return <GroupIconFour className={ cssClass } />
			case 'hands':
				return <HandsIcon className={ cssClass } />
			case 'hat':
				return <HatIcon className={ cssClass } />
			case 'laptop':
				return <LaptopIcon className={ cssClass } />
			case 'lineChart':
				return <LineChartIcon className={ cssClass } />
			case 'mail':
				return <MailIcon className={ cssClass } />
			case 'money':
				return <MoneyIcon className={ cssClass } />
			case 'note1':
				return <NoteIconOne className={ cssClass } />
			case 'note2':
				return <NoteIconTwo className={ cssClass } />
			case 'person1':
				return <PersonIconOne className={ cssClass } />
			case 'person2':
				return <PersonIconTwo className={ cssClass } />
			case 'person3':
				return <PersonIconThree className={ cssClass } />
			case 'tree':
				return <TreeIcon className={ cssClass } />
			default:
				return <PersonIconOne className={ cssClass } />
		}
	}

	return (
		<>
			{ getIconType( icon, cssClass ) }
		</>
	)
}