import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

import { BsArrowRight } from 'react-icons/bs'

export default function CarouselSlide ( props ) {

	const { slide, slideIndex, currentSlide, totalImages } = props
	const [ translateValue, setTranslateValue ] = useState()
	const [ isCurrentSlide, setIsCurrentSlide ] = useState()

	/**
	 * Allows images to infinite loop when incremented/numbers are negative, image at correct positive index returned
	 * @param {Number} imageIndex - images at index
	 * @param {Number} totalImages -- total number of images
	 */
	const modulus = ( imageIndex, totalImages ) => {
		return ( ( imageIndex % totalImages ) + totalImages ) % totalImages
	}

	useEffect(() => {

		const setSlide = ( transitionValue, isCurrentSlide ) => {
			setTranslateValue( transitionValue )
			setIsCurrentSlide( isCurrentSlide )
		}

		if ( currentSlide === slideIndex ) { // current slide
			setSlide( 0, true )
		} else if ( modulus( currentSlide + 1, totalImages ) === slideIndex ) { // set the next slide
			setSlide( 100, false )
		} else if ( modulus( currentSlide - 1 , totalImages) === slideIndex ) { // set the previous slide
			setSlide( -100, false )
		} else { // set other slides that aren't needed
			setSlide( 200, false )
		}

	}, [ currentSlide, slideIndex, totalImages ])

	return (
		<div
			className={`carousel__slider-item ${ isCurrentSlide ? 'active' : '' }`}
			style={{ transform: `translateX(${ translateValue }%)` }}>

				<div className="carousel__content-container">
					<div className="carousel__image-container">
						<Img fluid={{ ...slide.thumbnail.imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="carousel__slider-image" />
						<p className="carousel__meta-info">
							{ slide.name }, <span className="carousel__meta-company">{ slide.company }</span>
						</p>
					</div>
					<div className="carousel__summary-container">
						<p className="carousel__summary">{ slide.summary }</p>
						<Link to={ `/testimonials/${ slide.slug }` } className="carousel__link">
							Read more
							<BsArrowRight className="carousel__link-icon" />
						</Link>
					</div>
				</div>
		</div>
	)
}