import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { formatTitle } from '../../../helpers/stringHelpers'

import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'

export default function ExecutiveNetwork( props ) {

	const data = props.input

	return (
		<section className="executive-network">
			<AnimatedStroke pathType={ 'executive-network' } cssClass="executive-network__stroke" pixelDelay={ 250 } reverse={ true } />
			<div className="executive-network__wrapper wrapper">
				<div className="executive-network__heading-container">
					<h2 className="executive-network__heading" dangerouslySetInnerHTML={ formatTitle( data.title ) } />
				</div>
				<div className="executive-network__items-container">
					{ data.items.map( item => (
						<div className="executive-network__item" key={ item.id }>
							<Img fluid={{ ...item.image.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} className="executive-network__item-image" />
							<span className="executive-network__item-name">{ item.name }</span>
							<span className="executive-network__item-role">{ item.role }</span>
						</div>
					) ) }
				
				</div>
			</div>
		</section>
	)
}

ExecutiveNetwork.propTypes = {
	input: PropTypes.object.isRequired
};