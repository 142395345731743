import React from 'react'

import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'

export default function Image ( props ) {

	const { display } = props.input

	if ( !display ) return null

	return (
		<section className="divider">
			<AnimatedStroke pathType={ 'common-divider' } cssClass="divider__stroke" pixelDelay={ 250 } reverse={ true } />
		</section>
	)
}
