import React from 'react'

import ProfilingListItem from './ProfilingListItem'

export default function ProfilingList ( props ) {

	const { profilings } = props

	return (
		<section className="profiling">
			<div className="profiling__list">
				{ profilings.map( ( profiling, index ) => (
					<ProfilingListItem profiling={ profiling.profiling } index={ index } key={ profiling.id } />
				) ) }
			</div>
		</section>
	)
}