import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img  from 'gatsby-image'

import { formatTitle } from '../../../helpers/stringHelpers'

import { BsArrowRight } from 'react-icons/bs'

export default function Testimonial( props ) {

	const data = props.input

	if ( !data.testimonial ) {
		return null
	}

	return (
		<section className="testimonial-comp">
			<div className="testimonial-comp__wrapper wrapper">
				<div className="testimonial-comp__text-container">
					<p className="testimonial-comp__text" dangerouslySetInnerHTML={ formatTitle( data.introduction ) } />
					<Link to={ `/testimonials/${data.testimonial.slug}` } className="testimonial-comp__link">
						Read more
						<BsArrowRight className="testimonial-comp__link-icon" />
					</Link>
				</div>
				<div className="testimonial-comp__image-container">
					<Img fluid={{ ...data.testimonial.thumbnail.imageFile.childImageSharp.fluid }} className="testimonial-comp__image" />
				</div>
			</div>
		</section>
	)
}

Testimonial.propTypes = {
	input: PropTypes.object.isRequired
};