import React, { useState, useRef } from 'react'
import axios from 'axios'

import { formatTitle } from '../../../helpers/stringHelpers'

import { FaSpinner } from 'react-icons/fa'

const EMAIL_KEY = process.env.EMAIL_KEY || 'kieron123'

export default function ContactForm ( props ) {

	const message = props.input ? props.input.message : null

	const form = useRef();
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ company, setCompany ] = useState('')
	const [ messageDetails, setMessageDetails ] = useState('')
	const [ formSuccess, setFormSuccess ] = useState(false)
	const [ error, setError ] = useState('')
	const [ loading, setLoading ] = useState()

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		setLoading( true )

		const data = {
			name,
			email,
			company,
			messageDetails,
			EMAIL_KEY
		}

		try {
			const result = await axios.post('/api/email', data);

			if ( result.status === 200 ) {
				setFormSuccess( true )
				setLoading( false )
			}
		} catch ( error ) {
			console.log( error )
			setError('Error')
			setLoading( false )
		}
	}

	return (
		<section className= "contact-form">
			<div className="wrapper">
				{ message &&
					<h2 className="contact-form__header" dangerouslySetInnerHTML={ formatTitle( message ) } />
				}

				{ !formSuccess ? (
					<form className="form" onSubmit={ handleSubmit } ref={ form }>
						<div className="form__inputs-container-split" >
							<div className="form__input-container">
								<label className="form__label" htmlFor="name">Name</label>
								<input
									onChange={ e => { setName(e.target.value) }}
									value={ name }
									className="form__input" id="name" type="text" placeholder="Name" required />
							</div>

							<div className="form__input-container">
								<label className="form__label" htmlFor="company">Company</label>
								<input
									onChange={ e => { setCompany(e.target.value) }}
									value={ company }
									className="form__input" id="company" type="text" placeholder="Company" required />
							</div>

						</div>

						<div className="form__input-container">
							<label className="form__label" htmlFor="email">Email</label>
							<input
								onChange={ e => { setEmail(e.target.value) }}
								value={ email }
								className="form__input" id="email" type="email" placeholder="Email" required />
						</div>

						<div className="form__input-container">
							<label className="form__label" htmlFor="message">Message</label>
							<textarea
								onChange={ e => { setMessageDetails(e.target.value) }}
								value={ messageDetails}
								className="form__input" id="message" type="text" placeholder="Message" rows="1" required />
						</div>

						<input type="hidden" id="" name="" value=""></input>

						{ error.length >= 1 ? (
							<div className="form__error-container">
								<span className="form__error-message">Sorry, we have been unable to send your query.</span>
								<span className="form__error-message">Please try again later.</span>
							</div>
						) : (
							<button className="form__submit" type="submit">Send</button>
						) }

						{ loading &&
							<div className="form__loading-container">
								<div className="form__loading-background"></div>
								<FaSpinner className="loader" />
								<h3 className="form__loading-text">Sending</h3>
							</div>
						}
					</form>
				) : (
					<p className="contact-form__success">
						Thank you for contacting alitus. We will respond to your query as soon as possible!
					</p>
				) }
			</div>
		</section>
	)
}