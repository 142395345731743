import React from 'react'

export default function Pricing ( props ) {

	const { data } = props

	return (
		<div className="pricing__item">
			<h3 className="pricing__name">{ data.name }</h3>
			<span className="pricing__price">{ data.price }</span>

			<div className="pricing__description">{ data.description }</div>
		</div>
	)
}