import React, { useState } from 'react'
import Img from 'gatsby-image'

import { formatTitle } from '../../../helpers/stringHelpers'

import TeamMember from './TeamMember'
import ReactMarkdown from 'react-markdown'
import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'

export default function TeamMembersList ( props ) {

	const data = props.input

	const [ toggledMember, setToggledMember ] = useState()

	return (
		<section className="team-members" id="team-members-section">
			<div className="team-members__wrapper wrapper">
				<h2 className="team-members__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) } />

				<div className="team-members__list">
					{ data.members.map( member => (
						<TeamMember data={ member } setToggledMember={ setToggledMember } key={ member.id } />
					) ) }

					<div className={`team-members__info-container ${ toggledMember ? 'active' : '' }`}>
						{ toggledMember &&
							<div className="team-members__info-wrapper">
								<Img fluid={{ ...toggledMember.image.imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="team-members__info-image" />
								
								<div className="team-members__info-content">
									<span className="team-members__info-name">{ toggledMember.name }, <span className="team-members__info-role">{ toggledMember.role }</span></span>
									<ReactMarkdown
										className="team-members__description"
										escapeHtml={ false }
										source={ toggledMember.description }
									/>

									<button className="team-members__close" onClick={ () => setToggledMember() }>X</button>
								</div>
							</div>
						}
					</div>
				</div>
			</div>

			<AnimatedStroke pathType={ 'team-members-path' } cssClass="team-members__stroke" pixelDelay={ 250 } reverse={ true } />

		</section>
	)
}