import React from 'react'

import HomeHero from './homepage/HomeHero'
import HomeQuote from './homepage/HomeQuote'
import HomeThumbnail from './homepage/HomeThumbnail'
import HomeCompanies from './homepage/HomeCompanies'
import ExecutiveNetwork from './executive/ExecutiveNetwork'
import ExecutiveVideo from './executive/ExecutiveVideo'
import ExecutiveIconList from './executive/ExecutiveIconList'
import ExecutiveSchedule from './executive/ExecutiveSchedule'
import Carousel from './common/Carousel'
import ContactForm from './common/ContactForm'
import Divider from './common/Divider'
import Video from './common/Video'
import Image from './common/Image'
import Statement from './common/Statement'
import Testimonial from './common/Testimonial'
import Quote from './common/Quote'
import ArticleTextBlock from './article/ArticleTextBlock'
import ArticleImage from './article/ArticleImage'
import WorkshopsList from './workshop/WorkshopsList'
import TeamMembersList from './coaching/TeamMembersList'
import Pricing from './coaching/Pricing'
import WiderFamily from './about/WiderFamily'
import Training from './training/Training'
import ProfilingIntro from './profiling/ProfilingIntro'

import ProfilingList from '../ProfilingList/ProfilingList'
import ArticleQuote from './article/ArticleQuote'

export default function DynamicZone( { components } ) {

	return (
		<>
			{ components.map(( comp, index ) => {
				switch (comp.__typename) {
					case 'StrapiQuery_ComponentCommonCarousel':
						return <Carousel key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonContactForm':
						return <ContactForm key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonVideo':
						return <Video key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonImage':
						return <Image key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonStatement':
						return <Statement key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonTestimonial':
						return <Testimonial key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonQuote':
						return <Quote key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCommonDivider':
						return <Divider key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomepageHomepageHero':
						return <HomeHero key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomepageQuote':
						return <HomeQuote key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomepageThumbnail':
						return <HomeThumbnail key={ index } input={ comp } />
					case 'StrapiQuery_ComponentHomepageCompanies':
						return <HomeCompanies key={ index } input={ comp } />
					case 'StrapiQuery_ComponentExecutiveNetwork':
						return <ExecutiveNetwork key={ index } input={ comp } />
					case 'StrapiQuery_ComponentExecutiveVideo':
						return <ExecutiveVideo key={ index } input={ comp } />
					case 'StrapiQuery_ComponentExecutiveIconList':
						return <ExecutiveIconList key={ index } input={ comp } />
					case 'StrapiQuery_ComponentExecutiveSchedule':
						return <ExecutiveSchedule key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArticleTextBlock':
						return <ArticleTextBlock key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArticleImage':
						return <ArticleImage key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArticleQuote':
						return <ArticleQuote key={ index } input={ comp } />
					case 'StrapiQuery_ComponentArticleVideo':
						return <Video key={ index } input={ comp } cssClass="video--article" />
					case 'StrapiQuery_ComponentWorkshopsWorkshopsList':
						return <WorkshopsList key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCoachingTeamMembersList':
						return <TeamMembersList key={ index } input={ comp } />
					case 'StrapiQuery_ComponentCoachingPriceGuide':
						return <Pricing key={ index } input={ comp } />
					case 'StrapiQuery_ComponentAboutWiderFamily':
						return <WiderFamily key={ index } input={ comp } />
					case 'StrapiQuery_ComponentTrainingTraining':
						return <Training key={ index } input={ comp } />
					case 'StrapiQuery_ComponentProfilingProfileIntro':
						return <ProfilingIntro key={ index } input={ comp } />
					case 'StrapiQuery_ComponentProfilingProfilingList':
						return <ProfilingList key={ index } profilings={ comp.profilings } />
					default:
						return null
				}
			}) }
		</>
	)
}