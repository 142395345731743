import React from 'react'

import Icon from '../../Icon/Icon'

export default function ExecutiveIconListItem ( props ) {

	const { data } = props

	return (
		<li className="executive-icon-list__list-item">
			<Icon icon={ data.icon } cssClass="executive-icon-list__list-item-icon" />
			<div className="executive-icon-list__list-item-name">{ data.name }</div>
		</li>
	)
}