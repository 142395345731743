import React from 'react'
import PropTypes from 'prop-types'

import Video from '../common/Video'
import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'

export default function ExecutiveVideo( props ) {

	const data = props.input

	return (
		<section className="executive-video">
			<AnimatedStroke pathType={ 'executive-video' } cssClass="executive-video__stroke" pixelDelay={ 250 } reverse={ true } />
			<Video input={ data } />
		</section>
	)
}

ExecutiveVideo.propTypes = {
	input: PropTypes.object.isRequired
};