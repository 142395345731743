import React from 'react'
import { Link } from 'gatsby'

import AnimatedStroke from '../AnimatedStroke/AnimatedStroke'
import BehaviourIcon from '../../images/svgs/profiling-behaviour.svg'
import PersonalityIcon from '../../images/svgs/profiling-personality.svg'
import TalentsIcon from '../../images/svgs/profiling-talents.svg'

import { BsArrowRight } from 'react-icons/bs'

export default function ProfilingListItem ( props ) {

	const { profiling, index } = props

	const renderIcon = icon => {

		switch ( icon ) {
			case 'behaviour':
				return <BehaviourIcon className="profiling__icon" />
			case 'personality':
				return <PersonalityIcon className="profiling__icon" />
			case 'talents':
				return <TalentsIcon className="profiling__icon" />
			default:
				return <BehaviourIcon className="profiling__icon" />
		}
	}

	return (
		<div className={`profiling__list-item ${ index === 1 ? 'profiling__list-item--has-path' : '' }`}>

			{ index === 1 &&
				<AnimatedStroke pathType={ 'profile-1' } cssClass="profiling__stroke profiling__stroke--top" pixelDelay={ 200 } reverse={ false } />
			}

			<div className="wrapper">
				<div className="profiling__list-item-wrapper">
					<div className="profiling__content-container">
						<h2 className="profiling__heading">
							{ profiling.title } { profiling.buzzWord && <span className="profiling__heading-buzzword">- { profiling.buzzWord }</span> }
						</h2>
						<div className="profiling__summary">{ profiling.summary }</div>
						<Link to={ profiling.slug } className="profiling__link">
							Read more
							<BsArrowRight className="profiling__link-icon" />
						</Link>
					</div>
					<div className="profiling__icon-container">
						{ renderIcon( profiling.icon ) }
					</div>
				</div>
			</div>

			{ index === 1 &&
				<AnimatedStroke pathType={ 'profile-2' } cssClass="profiling__stroke profiling__stroke--bottom" pixelDelay={ 200 } reverse={ false } />
			}

		</div>
	)
}