import React from 'react'

import Icon from '../../Icon/Icon'

export default function TrainingItem ( props ) {

	const { data } = props

	return (
		<li className="training__list-item">
			<Icon icon={ data.icon } cssClass="training__list-item-icon" />
			<div className="training__list-item-name">{ data.name }</div>
		</li>
	)
}