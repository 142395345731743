import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function HomeCompanies( props ) {

	const data = props.input

	return (
		<section className="home-companies">
			<div className="home-companies__wrapper wrapper">
				<div className="home-companies__heading-container">
					<h2 className="home-companies__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) } />
				</div>
				<div className="home-companies__companies-container">
					{ data.companies.map( company => (
						<a href={ company.url } className="home-companies__company-link" target="_blank" rel="noreferrer" key={ company.id }>
							<Img fluid={ company.logo.imageFile.childImageSharp.fluid } className="home-companies__company-logo" />
						</a>
					) ) }
				</div>
			</div>
		</section>
	)
}

HomeCompanies.propTypes = {
	input: PropTypes.object.isRequired
};