import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function Statement( props ) {

	const data = props.input

	return (
		<section className="statement">
			<div className="statement__wrapper wrapper">
				<p className="statement__statement" dangerouslySetInnerHTML={ formatTitle( data.statement ) } />
				{ data.url && data.urlText && <Link to={ data.url } className="statement__link" dangerouslySetInnerHTML={ formatTitle( data.urlText ) } /> }
			</div>
		</section>
	)
}

Statement.propTypes = {
	input: PropTypes.object.isRequired
};