import React from 'react'
import PropTypes from 'prop-types'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function Quote( props ) {

	const data = props.input

	return (
		<section className="quote">
			<div className="quote__wrapper wrapper">
				<div className="quote__quote-container">
					<h2 className="quote__quote" dangerouslySetInnerHTML={ formatTitle( data.quote ) } />
					{ data.author && <span className="quote__quote-author" dangerouslySetInnerHTML={ formatTitle( data.author ) } /> }
				</div>
			</div>
		</section>
	)
}

Quote.propTypes = {
	input: PropTypes.object.isRequired
};