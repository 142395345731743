import React, { useState, useCallback } from 'react';

import CarouselSlide from './CarouselSlide'
import { formatTitle } from '../../../helpers/stringHelpers'

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

export default function Carousel ( props ) {

	const { carouselSlides, carouselTitle } = props.input

	const isSingleTestimonial = carouselSlides.length === 1 ? true : false
	// Double up the images for infinite loop effect, mainly for the 2 images fallback (requires 3 min otherwise)
	const testimonialSlides = isSingleTestimonial ? carouselSlides : [ ...carouselSlides, ...carouselSlides ]
	const totalImages = testimonialSlides.length

	const [ currentSlide, setCurrentSlide ] = useState( 0 )

	/**
	 * Allows images to infinite loop when incremented/numbers are negative, image at correct positive index returned
	 * @param {Number} imageIndex - images at index
	 * @param {Number} totalImages -- total number of images
	 */
	const modulus = ( imageIndex, totalImages ) => {
		return ( ( imageIndex % totalImages ) + totalImages ) % totalImages
	}

	const handleButtonClick = useCallback( increment => {
		setCurrentSlide( modulus( currentSlide + increment, totalImages ) )
	}, [ currentSlide, totalImages ])

	return (
		<div className="carousel">

			<div className="wrapper">
				<h2 className="carousel__title" dangerouslySetInnerHTML={ formatTitle( carouselTitle ) } />
			</div>

			<div className="carousel__wrapper wrapper">
				<div className="carousel__slider-container">
					{ testimonialSlides.map ( ( slide, index ) => (
						<CarouselSlide slide={ slide.testimonial } slideIndex={ index } currentSlide={ currentSlide } totalImages={ totalImages } key={ index } />
					) ) }
				</div>

				<RiArrowLeftSLine
					className={`carousel__button carousel__button--prev ${ isSingleTestimonial ? 'u-hide' : '' }`}
					onClick={ () => handleButtonClick( -1 ) } />

				<RiArrowRightSLine
					className={`carousel__button carousel__button--next ${ isSingleTestimonial ? 'u-hide' : '' }`}
					onClick={ () => handleButtonClick( 1 ) } />
			</div>
		</div>
	)
}