import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { formatTitle } from '../../../helpers/stringHelpers'

import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'

export default function HomeThumbnail( props ) {

	const data = props.input

	return (
		<section className="home-thumbnail">
			<div className="home-thumbnail__wrapper wrapper">
				<p className="home-thumbnail__text" dangerouslySetInnerHTML={ formatTitle( data.textBlock ) }></p>
				<div className="home-thumbnail__image-container">
					<Img fluid={{ ...data.thumbnail.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} className="home-thumbnail__image" />
				</div>
			</div>
			<AnimatedStroke pathType={ 'homepage-thumbnail' } cssClass="home-thumbnail__stroke" pixelDelay={ 225 } reverse={ true } />
		</section>
	)
}

HomeThumbnail.propTypes = {
	input: PropTypes.object.isRequired
};