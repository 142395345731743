import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { formatTitle } from '../../../helpers/stringHelpers'

import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'
import WorkshopItem from './WorkshopItem'

export default function WorkshopsList ( props ) {

	const queryData = useStaticQuery(graphql`
		query workshopsListQuery {
			strapi {
				workshops {
					id
					categoryTitle
					icon
					slug
				}
			}
		}
	`)

	const data = queryData.strapi.workshops

	return (
		<section className="workshops">
			<div className="wrapper">
				<h2 className="workshops__heading" dangerouslySetInnerHTML={ formatTitle( props.input.heading ) } />

				<ul className="workshops__list">
					{ data.map( workshop => (
						<WorkshopItem workshop={ workshop } key={ workshop.id } />
					) ) }
					</ul>
			</div>

			<AnimatedStroke pathType={ 'workshops' } cssClass="workshops__stroke" pixelDelay={ 250 } reverse={ false } />

		</section>
	)
}