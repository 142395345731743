import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function HomeHero( props ) {

	const data = props.input

	return (
		<section className="home-hero">
			<div className="home-hero__wrapper wrapper">
				<div className="home-hero__heading-container">
					<h2 className="home-hero__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) } />
				</div>
				<div className="home-hero__image-container">
					<div className="home-hero__images-stack">
						<Img fluid={{ ...data.image.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} className="home-hero__image home-hero__image--top" />
						<Img fluid={{ ...data.image.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} className="home-hero__image home-hero__image--bottom" />
					</div>
				</div>
			</div>
		</section>
	)
}

HomeHero.propTypes = {
	input: PropTypes.object.isRequired
};