import React from 'react'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function Video ( props ) {

	const { videoNumber, videoTitle } = props.input

	return (
		<div className={`video ${ props.cssClass ? props.cssClass : '' }`}>
			<div className="video__wrapper">
				<div className="video__inner-wrapper">
					<div className="video__container">
						<iframe
							className="video__player"
							src={`https://player.vimeo.com/video/${ videoNumber }`}
							title={ videoTitle }
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							frameBorder="0"
							webkitallowfullscreen="true"
							mozallowfullscreen="true"
							allowFullScreen
						/>
					</div>
				</div>
			</div>
			{ !!videoTitle &&
				<p className="video__title" dangerouslySetInnerHTML={ formatTitle( videoTitle ) } />
			}
		</div>
	)
}
