import React from 'react'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function ExecutiveSchedule ( props ) {

	const data = props.input

	return (
		<section className="executive-schedule">
			<div className="executive-schedule__wrapper wrapper">
				<div className="executive-schedule__header">
					<h2 className="executive-schedule__heading" dangerouslySetInnerHTML={ formatTitle( data.title ) } />
				</div>
				<ul className="executive-schedule__list">
					{ data.items.map( item => (
						<li className="executive-schedule__list-item" key={ item.id }>
							<div className="executive-schedule__info executive-schedule__info--name">{ item.name }</div>
							<div className="executive-schedule__info ">{ item.date }</div>
						</li>
					) ) }
				</ul>
			</div>
		</section>
	)

}