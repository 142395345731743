import React from 'react'

import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'
import WiderFamilyItem from './WiderFamilyItem'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function WiderFamily ( props ) {

	const data = props.input

	return (
		<section className="wider-family">
			<AnimatedStroke pathType={ 'common-path' } className="wider-family__stroke" pixelDelay={ 250 } reverse={ true } />
			<div className="wider-family__wrapper wrapper">
				<div className="wider-family__heading-container">
					<h2 className="wider-family__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) } />
				</div>

				<div className="wider-family__list">
					{ data.families.map( ( family, index ) => (
						<WiderFamilyItem data={ family } index={ index } key={ family.id } />
					) ) }
				</div>
			</div>
		</section>
	)
}