import React from 'react'

import ExecutiveIconListItem from './ExecutiveIconListItem'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function ExecutiveIconList ( props ) {

	const data = props.input

	return (
		<section className="executive-icon-list">
			<div className="executive-icon-list__wrapper wrapper">
				<div className="executive-icon-list__header">
					<h2 className="executive-icon-list__heading" dangerouslySetInnerHTML={ formatTitle( data.title ) } />
				</div>
				<ul className="executive-icon-list__list">
					{ data.icons.map( item => (
						<ExecutiveIconListItem data={ item } key={ item.id } />
					) ) }
				</ul>
			</div>
		</section>
	)

}