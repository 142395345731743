import React from 'react'

import Icon from '../../Icon/Icon'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function ProfilingIntro ( props ) {

	const data = props.input

	return (
		<section className="profiling-intro">
			<div className="profiling-intro__wrapper wrapper">
				<div className="profiling-intro__header">
					<h2 className="profiling-intro__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) } />
				</div>
				<ul className="profiling-intro__list">
					<li className="profiling-intro__list-item">
						<Icon icon={ data.stepOneIcon } cssClass="profiling-intro__list-item-icon" />
						<span className="profiling-intro__list-item-step">Step 01</span>
						<div className="profiling-intro__list-item-name">{ data.stepOneIntro }</div>
					</li>
					<li className="profiling-intro__list-item">
						<Icon icon={ data.stepTwoIcon } cssClass="profiling-intro__list-item-icon" />
						<span className="profiling-intro__list-item-step">Step 02</span>
						<div className="profiling-intro__list-item-name">{ data.stepTwoIntro }</div>
					</li>
					<li className="profiling-intro__list-item">
						<Icon icon={ data.stepThreeIcon } cssClass="profiling-intro__list-item-icon" />
						<span className="profiling-intro__list-item-step">Step 03</span>
						<div className="profiling-intro__list-item-name">{ data.stepThreeIntro }</div>
					</li>
				</ul>
				{ data.summary && <p className="profiling-intro__summary">{ data.summary }</p> }
			</div>
		</section>
	)

}