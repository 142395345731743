import React from 'react'
import { Link } from 'gatsby'

import Icon from '../../Icon/Icon'

export default function WorkshopItem ( props ) {

	const { workshop } = props

	return (
		<li className="workshops__list-item">
			<Link to={ workshop.slug } className="workshops__list-item-link">
				<Icon icon={ workshop.icon } cssClass="workshops__list-item-icon" />
				<div className="workshops__list-item-name">{ workshop.categoryTitle }</div>
			</Link>
		</li>
	)
}