import React from 'react'

import { formatTitle } from '../../../helpers/stringHelpers'

import PricingItem from './PricingItem'

export default function Pricing ( props ) {

	const data = props.input

	return (
		<section className="pricing">
			<div className="pricing__wrapper wrapper">
				<div className="pricing__header">
					<h2 className="pricing__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) } />
					{ data.summary && <p className="pricing__summary">{ data.summary }</p> }
				</div>
				<div className="pricing__list">
					{ data.sessions.map( session => (
						<PricingItem data={ session } key={ session.id } />
					) ) }
				</div>
			</div>

		</section>
	)
}