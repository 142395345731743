import React from 'react'
import PropTypes from 'prop-types'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function ArticleQuote( props ) {

	const data = props.input

	return (
		<div className="article-quote">
			<div className="article-quote__wrapper">
				<div className="article-quote__quote-container">
					<h2 className="article-quote__quote" dangerouslySetInnerHTML={ formatTitle( data.quote ) } />
					{ data.author && <span className="article-quote__quote-author">{ data.author }</span> }
				</div>
			</div>
		</div>
	)
}

ArticleQuote.propTypes = {
	input: PropTypes.object.isRequired
};