import React from 'react'

import AnimatedStroke from '../../AnimatedStroke/AnimatedStroke'
import TrainingItem from './TrainingItem'

import { formatTitle } from '../../../helpers/stringHelpers'

export default function Training ( props ) {

	const data = props.input

	return (
		<section className="training">
			<AnimatedStroke pathType={ 'training-path' } cssClass="training__stroke" pixelDelay={ 250 } reverse={ false } />
			<div className="training__wrapper wrapper">
				<div className="training__header">
					<h2 className="training__heading" dangerouslySetInnerHTML={ formatTitle( data.heading ) } />
				</div>
				<ul className="training__list">
					{ data.trainingItems.map( item => (
						<TrainingItem data={ item } key={ item.id } />
					) ) }
				</ul>
			</div>
		</section>
	)

}