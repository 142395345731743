import React from 'react'
import Img from 'gatsby-image'

export default function TeamMember ( props ) {

	const { data, setToggledMember } = props
	
	const handleClick = ( member ) => {

		setToggledMember( member )
		// add quick delay before scrolling down to allow render 1/10th second
		setTimeout(() => {
			const selectedContainer = document.getElementById( 'team-members-section' )
			selectedContainer.scrollIntoView( { behavior: "smooth", block: "start" } )
		}, 100)
	}

	return (
		<div className="team-members__member">
			<Img fluid={{ ...data.image.imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="team-members__image" />

			<span className="team-members__name">
				{ data.name }, <span className="team-members__role">{ data.role }</span>
			</span>

			<div className="team-members__toggle-container">
				<button className="team-members__toggle" onClick={ () => handleClick( data ) }>Read more <span>here</span></button>
			</div>
		</div>
	)
}